import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../img/clear.svg'


const _withScopeId = n => (_pushScopeId("data-v-64de7967"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "changename" }
const _hoisted_2 = { class: "bottomBtn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.lang == 'ar' ? 'reverse' : '')
  }, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      title: _ctx.$t(_ctx.title),
      width: "480px",
      height: "216px",
      "before-close": _ctx.handleClose,
      "close-on-click-modal": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            rules: _ctx.rule,
            ref: "changeFormRef",
            onsubmit: "return false;"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "",
                prop: "username"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.username,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.username) = $event)),
                    onBlur: _ctx.UserNameTrim,
                    onInput: _ctx.checkUserName,
                    placeholder: `${_ctx.$t('user.inputname')}`,
                    maxlength: "50"
                  }, _createSlots({ _: 2 }, [
                    (_ctx.formData.username.length)
                      ? {
                          name: "suffix",
                          fn: _withCtx(() => [
                            _createElementVNode("img", {
                              src: _imports_0,
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearAll && _ctx.clearAll(...args)))
                            })
                          ])
                        }
                      : undefined
                  ]), 1032, ["modelValue", "onBlur", "onInput", "placeholder"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            class: "save-style",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveName()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("user.save")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "before-close"])
  ], 2))
}